<template>
  <main id='Main'>
    <mainHeader :class='{hidden : $route.matched.some(({ name }) => (name == "Detail"))}' />
    <viewWrapper>
      <router-view />
    </viewWrapper>
  </main>
</template>

<style scoped>

</style>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
}
</script>

<style scoped>
  main {
    box-sizing: border-box;
    width: 100%;
  }
</style>
