<template>
  <router-link id='Back' :to='parentOrPrevious'>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 20'>
      <polyline points='10 0 0 10 10 20' />
    </svg>
    <slot />
  </router-link>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins], //kirby(), urltopath(), md() etc
  computed: {
    parentOrPrevious: function() {
      var from = this.route.from.fullPath.split('/').pop();
      if(from == this.kirby.page.parent) return './.';
      return './.';
    }
  }
}
</script>

<style scoped>

  #Back {
    transition: all 300ms linear;
    position: absolute;
    margin-top: -0.1em;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-size: var(--regular);
  }

  svg {
    position: relative;
    top: 0.15em;
    height: 1em;
    stroke-width: 1px;
    margin-right: 1.5em;
    fill: transparent !important;
  }
</style>

<style>
  #App:not(.white) a#Back svg {
    stroke: white;
  }

  header.hidden a#Back {
    transform: translateX(0rem);
    opacity: 1;
    pointer-events: all;
  }

  header:not(.hidden) a#Back {
    transform: translateX(1rem);
    opacity: 0;
    pointer-events: none;
  }

</style>
