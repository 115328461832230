<template>
  <nav :class='{active : show}'>
    <button @click='show=!show'>☰</button>
    <section v-show='show'>
       <router-link class='link'
                    @click.native='show=false'
                    :to='path(page.url)'
                    v-for='page in getPagesFromPaths(yaml(kirby.site.header).left)'
                    :key='page.uid'
                    v-html='page.strings.title' />
       <router-link class='link'
                    @click.native='show=false'
                    :to='path(page.url)'
                    v-for='page in getPagesFromPaths(yaml(kirby.site.header).right)'
                    :key='page.uid'
                    v-html='page.strings.title' />
       <languageNavigation />
       <footer>
         <span v-html='yaml(kirby.site.footer).copyright' />
       </footer>
    </section>
  </nav>
</template>

<style scoped>
  button {
    position: fixed;
    top: 0.75rem;
    right: var(--menuright);
    z-index: 1002;
    cursor: pointer;
    font-style: normal;
    font-size: 4rem;
    margin: 0;
    padding: 0;
  }

  #App.white section {
    background-color: rgba(255,255,255,0.9);
  }

  #App:not(.white) section {
    background-color: rgba(0,0,0,0.9);
  }

  section {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }

  footer {
    position: fixed;
    z-index: 1000;
    bottom: 5rem;
    right: 0;
    left: 0;
    font-size: 1rem;
  }

  a {
    font-family: 'GzaSemibold';
    font-weight: normal;
    font-size: 5rem;
    letter-spacing: -0.02em;
    line-height: 1.05em;
    display: block;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  section div {
    font-size: 1.5rem;
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
  }
</style>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins],
  data: function() {
    return {
      show: false
    }
  },
}
</script>
