/* Components */
import Vue from 'vue';

import arrow from './arrow.vue';
Vue.component('Arrow', arrow);

import MainHeader from './mainHeader.vue';
Vue.component('MainHeader', MainHeader);

import trophies from './trophies.vue';
Vue.component('Trophies', trophies);

import MainFooter from './mainFooter.vue';
Vue.component('MainFooter', MainFooter);

import HeaderNavigation from './headerNavigation.vue';
Vue.component('HeaderNavigation', HeaderNavigation);

import Drawing from './drawing.vue';
Vue.component('Drawing', Drawing);

import ViewWrapper from './viewWrapper.vue';
Vue.component('ViewWrapper', ViewWrapper);

import blocks from './blocks.vue';
Vue.component('Blocks', blocks);

import layout from './layout.vue';
Vue.component('Layout', layout);

import logos from './logos.vue';
Vue.component('Logos', logos);

import languageNavigation from './languageNavigation.vue';
Vue.component('LanguageNavigation', languageNavigation);

import backgroundVideo from './backgroundVideo.vue';
Vue.component('BackgroundVideo', backgroundVideo);

import featuredItems from './featuredItems.vue';
Vue.component('FeaturedItems', featuredItems);

import projectModule from './projectModule.vue';
Vue.component('ProjectModule', projectModule);

import videoBlock from './videoBlock.vue';
Vue.component('Videoblock', videoBlock);

import back from './back.vue';
Vue.component('Back', back);

import close from './close.vue';
Vue.component('Close', close);

import veil from './veil.vue';
Vue.component('Veil', veil);

import imageBox from './imageBox.vue';
Vue.component('ImageBox', imageBox);

import imageBoxWithFiles from './imageBoxWithFiles.vue';
Vue.component('ImageBoxWithFiles', imageBoxWithFiles);

import srcset from './srcset.vue';
Vue.component('Srcset', srcset);

import gallery from './gallery.vue';
Vue.component('Gallery', gallery);

import mobileNavigation from './mobileNavigation.vue';
Vue.component('MobileNavigation', mobileNavigation);

import relatedpages from './relatedPages.vue';
Vue.component('Relatedpages', relatedpages);
