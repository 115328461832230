<template>
  <main id='Home' v-if='kirby.page.template == "home"'>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p0.png' />-->
    <backgroundVideo :current.sync='current' :featured='featured' />
    <section class='content'>
      <big :class='{mobile : size=="phone"}'>
        <span v-html='kirby.page.strings.text' />
        <router-link class='button'
                     v-for='(linkedpage, index) in getPagesFromPaths(yaml(kirby.page.strings.morepage))'
                     v-html='kirby.page.content.readmore.value'
                     :to='urltopath(linkedpage.url)'
                     :key='index' />
      </big>
      <featuredItems v-if='size!="phone"' :current.sync='current' :featured='featured' :seemore='kirby.page.strings.seemore' />
    </section>
    <mainFooter />
  </main>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  data: function() {
    return {
      current: 0
    }
  },
  computed: {
    featured: function(){
      console.log();
      var featured = this.getPagesFromPaths(this.yaml(this.kirby.page.strings.featured));
      console.log(featured);
      return featured;
    }
  }
}
</script>


<style scoped>
  main {
    max-width: var(--main-max-width);
    padding: var(--main-padding);
    min-height: 38rem;
    height: calc(100vh - 7rem );
    margin: auto;
    box-sizing: border-box;
  }

  section.content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;
  }

  section.content big {
    flex-grow: 2;
    border-bottom: var(--thinline);
    padding-bottom: 2rem;
    max-width: 60%;
  }

  section.content big.mobile {
    width: 100%;
    max-width: 100%;
  }

  section.content nav {
    margin-left: 3rem;
    flex-grow: 1;
    border-bottom: var(--thinline);
  }
</style>

<style>

  #Home #Footer {
    margin-top: 2rem;
  }

  section.content big p {
    max-width: 13em;
  }

  section.content big a.button {
    font-family: CourierSansLLWeb-Regular;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: inherit;
    font-size: var(--regular);
  }
</style>
