<template>
  <main id='About' v-if='about.template == "default"' v-once>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p05.png' />-->
    <h1 v-html='about.strings.title' />
    <layout :content='JSON.parse(about.strings.layout)' :page='about' />
    <mainFooter />
  </main>
</template>

<style scoped>
  main {
    max-width: var(--main-max-width);
    padding: var(--main-padding);
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    margin-top: 8rem;
    margin-bottom: 4.5rem;
  }
</style>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  computed: {
    about: function() {
      if (this.kirby.page.uid == 'about') return this.kirby.page;
      return this.kirby.pages.about || false;
    }
  }
}
</script>
