<template>
  <figure class='logos'>
    <a v-for='(logo, index) in logos' v-html='logo.svg' :href='logo.url' :key='index' target='_blank' />
  </figure>
</template>

<script>
export default {
  props: ['logos']
}
</script>

<style scoped>
  figure {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 4em;
    padding-bottom: 6em;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  figure a {
    height: var(--logoheight);
    margin: 0 1rem;
  }
</style>

<style>
  figure.logos svg {
    height: 100%;
  }
</style>
