<template>
  <router-link id='Close' :to='parentOrPrevious'>
    <svg viewBox="0 0 100 100">
        <line x1="0" y1="0" x2="100" y2="100"/>
        <line x1="100" y1="0" x2="0" y2="100"/>
    </svg>
  </router-link>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins], //kirby(), urltopath(), md() etc
  computed: {
    parentOrPrevious: function() {
      var from = this.route.from.fullPath.split('/').pop();
      if(from == this.kirby.page.parent) return './.';
      return './.';
    }
  }
}
</script>


<style scoped>

  #Close {
    position: absolute;
    top: 2.5rem;
    left: 3rem;
  }

  #Close:hover {
    position: absolute;
    top: 2.5rem;
    left: 3rem;
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }

  svg {
    height: 1.5rem;
    stroke: var(--black);
    stroke-width: 5px;
  }
</style>
