<template>
  <nav class="featured items">
    <transition-group name='fade' tag='div'>
      <router-link :to='urltopath(feature.url)'
                   v-for='(feature, slug, index) in featured'
                   :key='slug'
                   class='transition'
                   v-show='index==current'>
        <h5 v-html='feature.strings.category' />
        <h2 v-html='feature.strings.title' />
        <summary v-html='feature.strings.coverdescription' />
        <trophies :trophies='yaml(feature.strings.trophies)' />
        <a class='seemore button' v-html='seemore' />
      </router-link>
    </transition-group>
    <footer>
      <nav class='numbers'>
        <button v-for='(number, index) in numbers'
                :key='number'
                :class='{active : index==current}'
                @click='update(number-1)'
                v-html='number' />
      </nav>
    </footer>
  </nav>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['current', 'featured', 'seemore'],
  methods: {
    update(number) {
      console.log(number);
      this.$emit('update:current', number);
    }
  },
  computed: {
    numbers: function(){
      return Object.keys(this.featured).length;
    }
  }
}
</script>

<style scoped>

  .fade-enter-active,
  .fade-leave-active {
    transition: all 2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  nav div {
    position: relative;
    width: 100%;
    height: 30rem;
  }

  nav a {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 0rem;
    width: 100%;
    user-select: none;
  }

  h2 {
    margin: auto;
    text-align: center;
    max-width: 9em;
    margin-bottom: 0.66em;
  }

  h5 {
    margin-bottom: 1em;
  }

  summary {
    font-size: var(--regular);
    line-height: 1.4em;
    letter-spacing: 0.02em;
    width: 100%;
    max-width: 36em;
    margin: auto;
    margin-bottom: 4em;
  }

  footer {
    display: flex;
    margin-bottom: 2em;
  }

  button {
    box-sizing: border-box;
    width: 2.25em;
    padding: 0.45em 0.5em 0.5em;
    width: 2.5em;
    border-radius: 50%;
    border: var(--thinline);
    margin-left: 0.5em;
    height: 2.5em;
    transition: all 100ms ease-in-out;
  }

  button:not(.active) {
    border-color: transparent;
  }

  nav.numbers:hover button {
    border-color: transparent;
  }

  nav.numbers:hover button:hover {
    cursor: pointer;
    border: var(--thinline);
  }

  a.seemore {
    position: absolute;
    bottom: -1.5rem;
    right: 0;
    width: 16.5em;
    text-align: right;
    text-transform: uppercase;
    z-index: 99;
    cursor: pointer;
    font-size: var(--regular);
  }

  a.fade-enter {
    opacity: 0;
    transition: opacity 1s ease-out;
  }

  a.fade-leave {
    opacity: 0;
    transition: opacity 1s ease-out;
  }

  a.fade-enter-from,
  a.fade-leave-to {
    opacity: 0;
  }

  a.fade-enter-to,
  a.fade-leave-from {
    opacity: 1;
  }

  div.trophies {
    margin-top: -1rem;
    height: 4rem;
    display: flex;
    justify-content: center;
  }

  div.trophies figure {
    max-height: 100%;
    min-width: 5em;
    overflow: auto;
  }

</style>

<style>
  nav.featured.items div.trophies {
    justify-content: center;
  }
</style>
