<template>
  <section>
    <figure v-for='(feature, slug, index) in featured' :key='slug'>
      <transition name='basic' class='transition'>
        <video class='transition'
               v-show='index==current&&started'
               :key='index'
               autoplay='true'
               :ref='("video"+index)'
               :preload='index < (current+1) ? "auto" : "false" '
               muted='true'
               playsinline
               @canplay='letplay(index)'
               @ended='next()'>
          <source v-if='yaml(feature.strings.cover).hires' :src='kirby.site.scheme + "://" + kirby.site.host + "/" + feature.uri + "/" + yaml(feature.strings.cover).hires' type='video/mp4'>
          <source v-if='yaml(feature.strings.cover).lores' :src='kirby.site.scheme + "://" + kirby.site.host + "/" + feature.uri + "/" + yaml(feature.strings.cover).lores' type='video/mp4'>
        </video>
      </transition>
    </figure>
  </section>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['current', 'featured'],
  data: function(){
    return {
      started: false
    }
  },
  methods: {
    letplay(index) {
      if(index == this.current){
        this.started = true;
      }
    },
    next() {
      var current = this.current + 1 < Object.keys(this.featured).length ? this.current + 1 : 0;
      console.log(current, this.featured, Object.keys(this.featured).length);
      this.$emit('update:current', current);
      console.log(this.$refs);
      this.$refs["video"+current][0].play();
    }
  }
}
</script>

<style scoped>
  video {
    position: fixed;
    object-fit: cover;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.95;
  }

.transition {
  will-change: opacity;
  transform: translate3d(0,0,0);
  --time: 250ms;
  --outtime: 125ms;
}

.transition.basic-enter-active {
  transition: all var(--time)var(--bezier) var(--outtime);
  position: absolute;
  top: 0;
  opacity: 0;
}
.transition.basic-enter-to {
  opacity: 1;
}
.transition.basic-leave-active {
  transition: opacity var(--outtime) var(--bezier) 1ms;
  position: absolute;
  top: 0;
}
.transition.basic-leave-to {
  opacity: 0;
}
.transition.basic-leave {
  opacity: 1;
}

div:empty {
  display: none;
}
</style>
