/* Router */

import Landing from './views/landing.vue';
import Home from './views/home.vue';
import Default from './views/default.vue';
import About from './views/about.vue';
import Projects from './views/projects.vue';
import Contact from './views/contact.vue';
import Detail from './views/detail.vue';
import News from './views/news.vue';
import Item from './views/newsitem.vue';

export default [
  {
    name: 'Root',
    path: '/',
    redirect: to => {
      if(document.documentElement.lang) return document.documentElement.lang;
    }
  },
  {
    path: '/:language',
    name: 'Landing',
    component: Landing,
    children: [
      {
        name: 'Home',
        path: '',
        component: Home
      },
      {
        name: 'About',
        path: 'about',
        component: About
      },
      {
        name: 'News',
        path: 'news',
        component: News,
        children: [
          {
            name: 'News Item',
            path: ':newsitem',
            components: {
              aside: Item
            }
          }
        ]
      },
      {
        name: 'Projects',
        path: 'projects',
        component: Projects
      },
      {
        name: 'Detail',
        path: 'projects/:project',
        component: Detail
      },
      {
        name: 'Contact',
        path: 'contact',
        component: Contact
      },
      {
        name: 'Default',
        path: '*',
        component: Default
      }
    ]
  },
]
/*
*/
