<template>
  <router-link :to='{ path: urltopath(project.url) }' v-once>
    <figure>
      <video ref='video' @mouseover='$refs["video"].play()' @mouseleave='$refs["video"].pause()' preload='auto' muted='true' loop v-if='yaml(project.strings.cover).lores'>
        <source :src='kirby.site.scheme + "://" + kirby.site.host + "/" + project.uri + "/" + yaml(project.strings.cover).lores' type='video/mp4'>
      </video>
      <!--<img v-else-if='yaml(project.strings.cover).image' :src='kirby.site.scheme + "://" + kirby.site.host + "/" + project.uri + "/" + yaml(project.strings.cover).image[0]'>-->
      <srcset :v-else-if='yaml(project.strings.cover).image' :file='project.files[whiteSpace(yaml(project.strings.cover).image)]' sizes='20vw'/>
    </figure>
    <h5 v-html='project.strings.category || ""' />
    <h3 v-html='project.strings.title' />
    <ul>
      <li>
        <span v-html='project.strings.productionstatus' />&emsp;<a v-html='project.strings.productionyear' />
      </li>
      <li v-for='(credit, index) in yaml(project.strings.maincredits)' :key='index'>
        <span v-html='credit.title' />&emsp;<a v-html='credit.participant' />
      </li>
    </ul>
  </router-link>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['project'],
  methods: {
    whiteSpace: function (a) {
        return a.toString().trim();
    }
  }
}
</script>

<style scoped>
  figure {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    padding-top: 66%;
    position: relative;
  }

  figure img:not(:hover) {
    opacity: 0.95;
  }

  figure video, figure img {
    transition: opacity 200ms var(--bezier);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }

  h3 {
    max-width: 8em;
    margin: auto;
    margin-bottom: 0.75em;
    text-align: center;
  }

  ul {
    font-size: var(--regular);
    display: block;
    list-style: none;
    line-height: 1.4em;
    margin: 0;
    padding: 0;
  }

  span {
    text-transform: uppercase;
    color: var(--gray);
  }
</style>
