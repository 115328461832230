<template>
  <hr @click='$router.go(-1)' :class='{"visible" : show}'/>
</template>

<style scoped>
  hr {
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    background-color: var(--black);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    mix-blend-mode: multiply;
    z-index: 99;
    cursor: pointer;
  }

  hr {
    transition: 0.5s opacity ease-in-out;
    pointer-events: none;
    opacity: 0;
  }

  hr.visible {
    pointer-events: all;
    opacity: 0.4;
  }
</style>

<script>
export default {
  props: ['show'],
}
</script>
