<template>
  <figure class='video' v-if='load'>
    <vimeo-player
      v-show='show'
      ref='player'
      :options='video.options'
      :video-id='block.content.url' />
    <button @click='toggle()' :class='video.playing ? "pause" : "play"'>
      <svg class='play'
           version='1.1'
           xmlns='http://www.w3.org/2000/svg'
           height='89px'
           viewBox='-1 -1 91 91'
           xml:space='preserve'>
        <path d='M44.5,0C19.9,0,0,19.9,0,44.5S19.9,89,44.5,89S89,69.1,89,44.5S69.1,0,44.5,0z M31.2,62.1V28.2l33.9,16.9L31.2,62.1z' />
      </svg>
    </button>
    <figcaption v-html='block.content.caption' />
  </figure>
</template>

<script>
module.exports = {
  props: ['block'],
  data: function(){
    return {
      load: false,
      show: false,
      video: {
        options: {
          controls: false,
          responsive: true,
          dnt: true
        },
        playing: false,
      }
    }
  },
  created() {
    if (!this.show) {
      setTimeout(()=>{
        this.load = true;
      },200);
      setTimeout(()=>{
        this.show = true;
      },400);
    }
  },
  methods: {
    onReady() {
      this.playerReady = true
    },
    toggle() {
      if(this.video.playing) {
        this.$refs.player.pause();
        this.video.playing = false;
      } else {
        this.$refs.player.play();
        this.video.playing = true;
      }
    },
    pause() {
      if(this.video.playing) {
        this.$refs.player.pause();
        this.video.playing = false;
      }
    }
  }
}
</script>


<style scoped>
figure {
  cursor: pointer;
}
figure.video {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 64rem;
}

button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

button.pause svg {
  opacity: 0;
}

button svg {
  transition: opacity 0.5s;
  width: 9rem;
  height: 9rem;
  fill: rgba(230,230,230, 0.8) !important;
  stroke: transparent;
  stroke-width: 0;
}

figure.video > div {
  transition: opacity 0.5s var(--bezier);
}
</style>

<style>

  figure.video {
    width: 100%;
    padding-bottom: 56.255%;
    position: relative;
    top:0;
    overflow: visible;
    margin-bottom: 3rem;
  }

  figure.video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: block;
  }

  figure.video figcaption {
    position: absolute;
    z-index: 99;
    display: block;
    bottom: -2.5rem;
    left: 0;
    right: 0;
  }

</style>
