<template>
  <main id='Default'>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p010.png' />-->
    <!--<h1 v-html='kirby.page.strings.title' />-->
    <layout :content='stringtojson(kirby.page.strings.layout)' :page='kirby.page' />
    <mainFooter />
  </main>
</template>

<style scoped>
  main {
    max-width: var(--main-max-width);
    padding: var(--main-padding);
    margin-left: auto;
    margin-right: auto;
    margin-top: 16rem;
  }
</style>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
}
</script>
