<template>
  <aside id='News-Item' v-if='kirby.page.template == "newsitem"' v-scroll-lock='scrollLock'>
    <header>
      <close />
    </header>
    <article>
      <gallery v-if='kirby.page.strings.gallery'
               v-for='gallery in stringtojson(kirby.page.strings.gallery)'
               :filenames='gallery.content.images'
               :files='kirby.page.files'
               sizes='60vw' />
      <section>
        <h5 v-if='kirby.page.strings.date' class='date' v-html='kirby.page.strings.date' />
        <h2 v-html='kirby.page.strings.title' />
        <div v-html='kirby.page.strings.text' />
        <nav class='buttons' v-if='yaml(kirby.page.strings.buttons)'>
          <h4 v-if='kirby.page.strings.buttons' v-html='yaml(kirby.site.ui).newslinks'/>
          <a v-for='button in yaml(kirby.page.strings.buttons)' :href='button.link' v-html='button.content' target='_blank' />
        </nav>
      </section>
    </article>
  </aside>
</template>

<style scoped>
  header {
    position: fixed;
    top: 0;
    padding: 6rem 3rem;
    width: var(--newswidth);
    right: 0;
    box-sizing: border-box;
  }

  aside#News-Item {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: var(--newswidth);
    z-index: 9999;
    background-color: var(--whitebg);
    padding: var(--overlaypadding);
    box-sizing: border-box;
    overflow-y: scroll;
    text-align: left;
  }

  article {
    margin: 3rem;
    box-sizing: border-box;
  }

  article section {
    margin: 3rem;
  }

  @media screen and (max-width: 800px) {
    article section {
      margin: 2rem;
      margin-bottom: 20rem;
    }
  }

  article div {
    font-size: var(--regular);
    line-height: 1.6em;
  }

  nav.buttons {
    text-align: left;
    width: 100%;
    margin-bottom: 2rem;
  }

  nav.buttons a {
    border: var(--thinline);
    padding: 0.4em 1.3em 0.5em;
    border-radius: 2em;
    margin-right: 0.75em;
    font-size: var(--regular);
    position: relative;
    left: -0.1em;
  }
</style>

<script>
import mixins from '.././mixins.js';
import Vue from 'vue';
import VScrollLock from 'v-scroll-lock'

Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
  },
})

export default {
  mixins: [mixins], //kirby(), urltopath(), etc,
  data: function() {
    return {
      scrollLock: true,
    }
  },
  activated: function(){
    this.scrollLock = true;
  },
  deactivated: function(){
    this.scrollLock = false;
  }
}
</script>
