<template>
  <main id='Default' v-if='kirby.page.template=="contact"'>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p010.png' />-->
    <big v-html='kirby.page.content.intro.kirbytext' />
    <header>
      <a class='button' :href='"mailto:"+kirby.page.strings.contactlink' v-html='kirby.page.strings.contact'/>
      <ul>
        <li v-for='info in kirby.page.content.info'>
          <h6 v-html='info.title.value' />
          <span v-html='info.content.value' />
        </li>
      </ul>
    </header>
    <section>
      <div :class='section.type' v-for='section in kirby.page.content.staff.staff'>
        <h2 v-if='section.type == "heading"' v-html='section.content.text' />
        <h2 v-if='section.type == "headings" && $route.params.language == "en"' v-html='section.content.english' />
        <h2 v-if='section.type == "headings" && $route.params.language == "fi"' v-html='section.content.finnish' />
        <figure v-if='section.type =="contact"'>
          <picture>
            <srcset :file='kirby.page.files[section.content.mugshot[0]]' sizes='20rem' />
          </picture>
          <figcaption v-if='section.content.infos'>
            <h6 v-if='$route.params.language == "fi"' v-html='section.content.infos.titteli' />
            <h6 v-else v-html='section.content.infos.title' />
            <span v-html='section.content.infos.content' />
            <a v-if='section.content.infos.email' :href='"mailto:"+section.content.infos.email' v-html='section.content.infos.email' />
          </figcaption>
        </figure>
      </div>
    </section>
    <mainFooter />
  </main>
</template>

<style scoped>
main {
  max-width: var(--main-max-width);
  padding: var(--main-padding);
  margin-left: auto;
  margin-right: auto;
  color: var(--rootcolor);
  text-align: left;
}

big {
  margin-top: 12rem;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  flex-direction: var(--columndirection);
}

a.button {
  font-size: var(--regular);
  margin-bottom: 2rem;
}

ul {
  font-size: var(--regular);
  list-style: none;
  text-align: left;
  columns: 2;
  column-gap: 3em;
  margin: 0;
  max-width: 50%;
  padding: 0;
}

@media screen and (max-width: 800px) {
  ul {
    columns: 1;
  }
}

ul li {
  font-size: var(--regular);
  margin-bottom: 1.2em;
  width: 100%;
}

h6 {
  font-size: var(--regular);
  text-transform: uppercase;
  margin: 0;
  font-size: inherit;
  color: var(--gray);
  padding-right: 2em;
  box-sizing: border-box;
}

figure {
  padding: 0;
  margin: 0;
}

figure picture {
  position: relative;
  width: 100%;
  display: block;
  padding-bottom: 100%;
  margin-bottom: 2rem;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(80,80,80,1) 0%, rgba(20,20,20,1) 100%);

}

figure picture img {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

figure picture img {
  filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  transition: all .5s ease-in-out;
}

figure picture img:hover {
  filter: grayscale(0);
}

figcaption {
  font-size: var(--regular);
}

section {
  display: block;
}

section::after {
  content: "";
  display: block;
  clear: both;
}

section div {
  float: left;
  display: inline-block;
  vertical-align: top;
  width: var(--contactblockwidth);
  height: var(--contactblockheight);
  margin-right: 2.5%;
  margin-bottom: 3rem;
}

section div.headings, section div.heading {
  overflow: hidden;
  width: 100%;
  height: 7rem;
}
</style>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
}
</script>
