<template>
  <article>
    <section v-for='(section, alpha) in content' :key='alpha'>
      <!-- Columns -->
      <div v-for='(column, beta) in section.columns' :key='beta'>
        <!-- Blocks -->
        <template v-for='(block, index) in column.blocks'>

          <videoblock v-if='block.type=="video"' :block='block' :key='index' />

          <!-- Heading -->
          <heading v-if='block.type=="heading"'
                   :level='block.content.level'
                   :text='block.content.text'/>

          <!-- Text -->
          <div v-if='block.type=="text"'
               v-html='block.content.text'/>

          <relatedpages v-if='block.type=="relatedpages"' :paths='mapStructure(block.content.rows)' :title='block.content.title' />

          <!-- Ruler -->
          <hr v-if='block.type=="ruler"'>

          <!-- Figure -->
          <figure class='image' v-if='block.type=="image"'>
            <srcset :class='{"round" : block.content.crop}' :file='page.files[block.content.image[0]]' />
          </figure>

          <!-- Button -->
          <button v-if='block.type=="link-button"'
                  @click='goTo(block.content.target)'
                  :class='[ (block.content.fullwidth == "true") ? "fullwidth" : "" , block.content.alignment ]' />

          <!-- List -->
          <div v-if='block.type=="list"' v-html='block.content.text'/>

          <!-- Figure -->
          <!--<gallery v-if='block.type=="gallery"'
                   :filenames='block.content.images'
                   :files='page.files'
                   :sizes='columnWidth(column.width)+"vw"' /> -->

          <!-- Quote -->
          <blockquote v-if='block.type=="quote"' v-html='block.content.text'/>

          <!-- Logos -->
          <logos v-if='block.type=="logos"' :logos='block.content.rows'/>

          <!-- Table -->
          <table v-if='block.type=="table"'>
            <tr v-for='(row, index) in block.content.rows' :key='index'>
              <td v-html='row.title' />
              <td v-html='row.content' />
            </tr>
          </table>
        </template>
        <!-- Blocks End -->
      </div>
      <!-- Columns End -->
    </section>
  </article>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['content', 'page'],
  methods: {
    columnWidth: function(width) {
      return parseInt(100 * eval(width));
    },
    mapStructure: function(structure){
      structure = structure.map(url => url['title'][0]);
      return structure
    }
  }
}
</script>

<style scoped>
  article > section {
    width: var(--columnwidth);
    display: flex;
    flex-direction: var(--columndirection);
    gap: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    color: var(--rootcolor);
  }

  article section div:only-child {
    width: 100%;
  }

  article section div:not(:only-child) {
    width: var(--leftcolumnwidth);
  }

  article section div:not(:only-child) + div {
    width: var(--rightcolumnwidth);
    padding-right: 6em;
    box-sizing: border-box;
  }

  figure.image {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    height: 30rem;
    position: relative;
  }

  figure.image img {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }


  hr {
    border: none;
    border-top: var(--thinline);
    outline: none;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  article section table {
    column-count: 2;
    display: block;
  }

  article section table tr {
    display: block;
    text-align: left;
  }

  article section table td {
    display: block;
    font-size: var(--regular);
  }
</style>

<style>
  article > section > div p {
    font-size: var(--regular);
    line-height: 1.5em;
    text-align: left;
    max-width: 54em;
  }

  article > section > table td {
    font-size: 1.1rem;
    line-height: 1.5em;
    text-align: left;
  }
</style>
