<template>
  <img :src='src'>
</template>

<script>
export default {
  props: ['src']
}
</script>

<style scoped>
  img {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0.5;
    z-index: -1;
  }
</style>
