<template>
  <div>
    <a :href='getPathInLangue("en")' v-html='"EN"' :class='{active : $route.params.language == "en"}' />
    <a :href='getPathInLangue("fi")' v-html='"FI"' :class='{active : $route.params.language == "fi"}' />
  </div>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  methods: {
    getPathInLangue: function(language){
      return this.$route.path.replace(this.$route.params.language, language);
    }
  }
}
</script>

<style scoped>
  div  {
    z-index: 101;
    top: -0.6em;
    display: flex;
  }

  a {
    display: block;
    box-sizing: border-box;
    width: 2.25em;
    padding: 0.6em 0.5em 0.5em;
    width: 2.5em;
    border-radius: 50%;
    border: var(--thinline);
    margin-left: 0.5em;
    height: 2.5em;
  }

  a:not(.active) {
    border-color: transparent;
  }
</style>
