<template>
  <div class='trophies'>
    <figure class='logo' v-for='trophy in trophies' v-html='trophy.svg' />
  </div>
</template>

<script>
export default {
  props: ['trophies']
}
</script>

<style>
  div.trophies {
    margin-top: -1rem;
    height: 4rem;
    display: flex;
  }
  div.trophies figure {
    max-height: 100%;
    min-width: 5em;
    overflow: auto;
  }
</style>

<style>
  div.trophies svg {
    height: 100%;
    width: 100%;
  }

  div.trophies svg * {
    fill: var(--white) !important;
  }
</style>
