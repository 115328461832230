<template>
  <nav :class='align'>
    <router-link class='link'
                 :to='path(page.url)'
                 v-for='page in pages'
                 :key='page.uid'
                 v-html='page.strings.title' />
    <slot />
  </nav>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins],
  props: ['pages', 'align']
}
</script>

<style scoped>
  nav {
    display: flex;
    align-items: center;
    width: calc(40vw - 7rem);
  }

  nav a {
    padding-right: 1.5em;
  }

  nav.left {
    justify-content: flex-start;
  }

  nav.right {
    justify-content: flex-end;
  }

  a.link {
    text-transform: uppercase;
    letter-spacing: 0.07em;
    height: 1.5em;
  }
</style>
