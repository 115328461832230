<template>
  <main id='News' v-if='news.template == "news"'>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p02.png' />-->
    <router-view name='aside' />
    <veil :show='$route.matched.some(({ name }) => (name == "News Item"))' />
    <h1 v-if='news.strings' v-html='news.strings.title' />
    <header v-if='news.strings'>
      <nav v-if='news.strings.categories'>
        <button :class='{active: selectedCategory=="All"}' @click='selectedCategory="All"' v-html='yaml(kirby.site.ui).all' />
        <button :class='{active: selectedCategory==category}' once @click='selectedCategory=category' v-html='category' v-for='(category, index) in news.strings.categories.split(", ")' :key='index' />
      </nav>
      <nav>
        <button :class='{active: !selectedYears.length}' @click='selectedYears=[]' v-html='yaml(kirby.site.ui).all' />
        <button :class='{active: yearIsActive(year)}' @click='selectedYears=[year]' v-for='(year) in years' v-html='year' />
      </nav>
    </header>
    <nav id='News'>
      <router-link :to='newsitem.uri' v-for='newsitem in news.children' v-show='categoryIsSelected(newsitem.strings.category) && yearIsSelected(toYear(newsitem.strings.date))'>
        <div>
          <h5 class='date' v-html='newsitem.strings.date' />
          <imageBoxWithFiles v-for='gallery in stringtojson(newsitem.strings.gallery)':file="newsitem.files[gallery.content.images[0]]" sizes='10vw' />
        </div>
        <summary>
          <h5 v-html='newsitem.strings.category' />
          <h3 v-html='newsitem.strings.title' />
          <small v-html='newsitem.strings.excerpt' />
        </summary>
      </router-link>
    </nav>
    <mainFooter />
  </main>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  data: function() {
    return {
      selectedCategory: 'All',
      selectedYears: [],
    }
  },
  methods: {
    categoryIsSelected: function(categoriesstring) {
      let categories = categoriesstring.split(', ');
      categories.push('All')
      return categories.includes(this.selectedCategory);
    },
    yearIsSelected: function(year) {
      return this.yearIsActive(year) || this.selectedYears.length < 1;
    },
    yearIsActive: function(year) {
      return this.selectedYears.includes(year);
    },
    toYear: function(date) {
      return new Date(date).getFullYear();
    }
  },
  computed: {
    news: function() {
      if (this.kirby.page.template == 'news') return this.kirby.page;
      return this.kirby.pages.news;
    },
    dates: function() {
      return Object.keys(this.news.children).map(newsitem => this.news.children[newsitem].strings.date);
    },
    allyears: function() {
      return this.dates.map(date  => this.toYear(date));
    },
    years: function() {
      return[...new Set(this.allyears)];
    }
  }
}
</script>

<style scoped>
  main {
    max-width: var(--main-max-width);
    padding: var(--main-padding);
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    margin-top: 8rem;
    margin-bottom: 4.5rem;
  }

  h2 {
    max-width: 40rem;
  }

  h5 {
    font-size: var(--regular);
    text-align: left;
  }

  header nav * {
    text-transform: uppercase;
    letter-spacing: 0.015em;
  }

  button:not(:first-of-type) {
    margin-left: 3rem;
  }

  button {
    position: relative;
    padding: 0;
    padding-bottom: 0.33em;
    display: inline-block;
    border-bottom: 1px solid transparent;
  }

  button.active, button:hover {
    border-bottom: var(--thinline);
  }

  nav:hover button {
    border-bottom: 1px solid transparent;
  }

  button.active, nav:hover button:hover {
    cursor: pointer;
    border-bottom: var(--thinline);
  }

  button:not(:last-of-type)::after {
    content: '/';
    position: absolute;
    right: -2rem;
  }

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: var(--columndirection);
    margin-bottom: 3rem;
  }

  nav#News {
    width: 100%;
    overflow: hidden;
  }

  nav#News a {
    display: flex;
    flex-direction: var(--columndirection);
    justify-content: space-between;
    user-select: none;
    margin-bottom: 3rem;
  }

  nav#News a div {
    border-top: var(--thinline);
    flex-grow: 1;
    flex-shrink: 0;
    max-width: var(--leftcolumnwidth);
  }

  nav#News a summary {
    flex-grow: 2;
    flex-shrink: 0;
    max-width: var(--rightcolumnwidth);
    text-align: left;
    padding-bottom: 3rem;
  }

  @media screen and (min-width: 800px) {
    nav#News a summary {
      border-top: var(--thinline);
    }
  }

  nav#News a summary small {
    letter-spacing: 0.03em;
    display: block;
    font-size: var(--regular);
    line-height: 1.6em;
    max-width: 48em;
  }
</style>
