<template>
  <svg class='arrow'
       :style='style'
       version='1.1'
       xmlns='http://www.w3.org/2000/svg'
       x='0px'
       y='0px'
       viewBox='0 0 52 52'>
    <ellipse class="stroke" cx="26" cy="26" rx="25" ry="25"/>
    <polyline class="stroke" points="23.69 33.71 15.94 25.22 23.69 16.74"/>
    <line class="stroke" x1="15.94" y1="25.22" x2="35.04" y2="25.22"/>
  </svg>
</template>

<style>
  svg.arrow {
    position: relative;
    bottom: -0.35rem;
    width: 3rem;
    height: 2.65rem;
  }

  svg .stroke {
    stroke: var(--white);
    stroke-width: 0.1em;
    fill: transparent;
  }

</style>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    style: function() {
      var style = '';
      if(this.direction == 'left'){
        style = style + 'transform: scaleX(-1);';
      }
      if(this.direction == 'right'){
        style = style + 'transform: scaleX(1);';
      }
      return style;
    }
  }
}
</script>
