<template>
  <keep-alive>
    <figure class='imagebox'>
      <srcset v-if='load' :class='{content: true, show: show}' :file="file" :sizes='sizes' />
      <srcset v-if='load' :class='{background: true, show: show}' :file="file" :sizes='sizes' />
    </figure>
  </keep-alive>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins],
  props: ['file','sizes'],
  data: function() {
    return {
      load: false,
      show: false
    }
  },
  created() {
    if (!this.show) {
      setTimeout(()=>{
        this.load = true;
      },10);
      setTimeout(()=>{
        this.show = true;
      },320);
    }
  }
}
</script>

<style>
  figure.imagebox {
    width: 100%;
    height: 0;
    padding-bottom: 66%;
    overflow: hidden;
    position: relative;
    border-radius: 0.75rem;
    margin: 0;
    background-color: var(--black);
  }

  figure.imagebox img.content {
    transition: opacity 500ms var(--bezier) 500ms;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);
    z-index: 10;
    object-fit: contain;
  }

  figure.imagebox img.background {
    transition: all 300ms var(--bezier);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(1rem);
    transform: scale(1.5);
  }

  figure.imagebox img:not(.show) {
    opacity: 0;
    transform: scale(2);
  }

  figure.imagebox img.content.show {
    opacity: 1;
    transform: scale(1);
  }

  figure.imagebox img.background.show {
    opacity: 0.7;
    transform: scale(1.5);
  }
</style>
