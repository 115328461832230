<template>
  <div>
    <template v-for='(block, index) in content' v-once>
      <videoblock v-if='block.type=="video"' :block='block' :key='index' />

      <!-- Heading -->
      <heading v-if='block.type=="heading"'
               :level='block.content.level'
               :text='block.content.text'
               :key='index' />

      <!-- Text -->
      <div v-if='block.type=="text"'
           v-html='block.content.text'
           :key='index' />

      <!-- Ruler -->
      <hr v-if='block.type=="ruler"' :key='index'>

      <!-- Figure -->
      <figure v-if='block.type=="image"' :key='index'>
        <srcset :class='{"round" : block.content.crop}' :file='page.files[block.content.image[0]]' />
      </figure>

      <!-- Button -->
      <button v-if='block.type=="link-button"'
              @click='goTo(block.content.target)'
              :class='[ (block.content.fullwidth == "true") ? "fullwidth" : "" , block.content.alignment ]'
              v-html='block.content.caption'
              :key='index' />

      <!-- List -->
      <div v-if='block.type=="list"' v-html='block.content.text' :key='index' />

      <!-- Gallery -->
      <gallery v-if='block.type=="gallery"'
               :filenames='block.content.images'
               :files='page.files'
               sizes='60vw' />

      <!-- Quote -->
      <blockquote v-if='block.type=="quote"' v-html='block.content.text' :key='index' />

      <!-- Logos -->
      <logos v-if='block.type=="logos"' :block='block' :key='index' />

      <!-- Table -->
      <table v-if='block.type=="table"' :key='index'>
        <tr v-for='row in block.content.rows' :key='row.content'>
          <td v-html='row.title' />
          <td v-html='row.content' />
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['content', 'page'],
  methods: {
    columnWidth: function(width) {
      return parseInt(100 * eval(width));
    },
  }
}
</script>

<style scoped>
  article > section {
    width: var(--columnwidth);
    display: flex;
    flex-direction: var(--columndirection);
    gap: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    color: var(--rootcolor);
  }

  article section div:only-child {
    width: 100%;
  }

  article section div:not(:only-child) {
    width: calc(41.25% - 2rem);
  }

  article section div:not(:only-child) + div {
    width: calc(58.75% - 2rem);
    padding-right: 6em;
    box-sizing: border-box;
  }

  figure {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
  }

  hr {
    border: none;
    border-top: var(--thinline);
    outline: none;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  article section table {
    column-count: 2;
    display: block;
  }

  article section table tr {
    display: block;
    text-align: left;
  }

  article section table td {
    display: block;
  }
</style>

<style>
  article > section > div p {
    font-size: 1.366em;
    line-height: 1.4em;
    text-align: left;
  }
</style>

<style>
  div#App:not(.white) article {
    color: #FFF;
    transition: color 1000ms linear;
  }

  div#App.white article {
    transition: color 1000ms linear;
    color: #000;
  }
</style>
