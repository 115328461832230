<template>
  <nav class='relatedpages'>
     <h4 v-html='title'/>
     <projectModule v-for='(project, slug) in getPagesFromPaths(paths)'
                        :key='slug'
                        :project='project'
                        v-show='project.visible' />
  </nav>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['paths', 'title']
}
</script>

<style scoped>

  nav.relatedpages {
    margin-left: -1rem;
    display: flex;
    justify-content: center;
    flex-direction: var(--columndirection);
    flex-wrap: wrap;
    margin-top: 9rem;
  }

  nav.relatedpages h4 {
    width: 100%;
    margin-bottom: 6rem;
  }

  nav.relatedpages a {
    margin-bottom: 4rem;
    margin-left: var(--projectitemsidemargin);
    margin-right: var(--projectitemsidemargin);
    width: var(--projectitemwidth);
    box-sizing: border-box;
  }

</style>
