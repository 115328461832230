<template>
  <footer id='Footer' :class='{"mobile" : (size=="phone") }'>
    <span v-if='size!="phone"' v-html='yaml(kirby.site.footer).copyright' />
    <logos :logos='yaml(kirby.site.footer).logos' />
    <nav>
      <span v-if='size!="phone"' v-html='yaml(kirby.site.footer).follow' />
      <a :href='media.url' target='_blank' v-for='(media, index) in yaml(kirby.site.footer).socialmedia' v-html='media.title' :key='index' />
    </nav>
  </footer>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins]
}
</script>

<style scoped>
  span {
    margin-right: 2rem;
  }

  footer {
    font-size: var(--regular);
    display: flex;
    align-items: center;
    max-width: var(--main-max-width);
    margin: 16rem auto 1rem;
    color: var(--rootcolor);
    position: relative;
    box-sizing: border-box;
  }

  footer.mobile {
    font-size: 0.8rem;
  }

  nav {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    margin-left: auto;
  }

  nav a {
    margin-left: 1em;
  }
</style>

<style >
  footer#Footer p {
    margin: 0;
    padding: 0;
  }

  footer#Footer figure.logos {
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  footer#Footer figure.logos a {
    height: 2rem;
    padding: 0;
    margin: 0 2rem 0 0;
  }

  footer#Footer figure.logos svg {
    height: 2rem;
    padding: 0;
  }
</style>
